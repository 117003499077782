import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Layout from "../components/common/Layout";
import Hero from "../components/marketing/Hero";
import HowItWorks from "../components/marketing/HowItWorks";
import RepScores from "../components/marketing/RepScores/RepScores";
import YouTubeIndex from "../components/youTube/YouTubeIndex";
import mixpanel from "mixpanel-browser";
import { getRndInteger } from "../utils/helpers";
import { ACTIVE_VARIANTS } from "../utils/constants";
import CarouselContainer from "../components/marketing/CarouselComponent/CarouselContainer";
import { useAuth } from "../components/context/auth";
import AudiencesSection from "../components/mainPageAudiencesSection";

const MIX_PANEL_KEY = "5c3b5b76d5af2b9dccfca8c23a0b6795";

mixpanel.init(MIX_PANEL_KEY, {
  debug: true,
  ignore_dnt: true,
  persistence: "localStorage",
});

const Index = ({ randomNumber }) => {
  const howItWorksRef = React.useRef(null);

  const { user, loading } = useAuth();

  const learnMoreOnClick = () => {
    if (howItWorksRef) {
      howItWorksRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    if (loading) return;
    if (
      !user &&
      window.location.toString().includes("https://www.teammateme.com/")
    ) {
      const trackedCheck = localStorage.getItem("tracked");
      if (trackedCheck === "true") return;
      const variantName = ACTIVE_VARIANTS[randomNumber];
      const gtagEvent = "before-signup-" + variantName;
      console.log("gtagEvent:", gtagEvent);
      gtag("event", gtagEvent);
      mixpanel.track(`${variantName},"Loaded"`, {
        variantName: "LOADED",
      });
      localStorage.setItem("variant", variantName);
    }
  }, [user, loading]);

  return (
    <div className="indexpage">
      <Layout isHomePage={true}>
        <Hero randomNumber={randomNumber} learnMoreOnClick={learnMoreOnClick} />
        <AudiencesSection />
        <HowItWorks howItWorksRef={howItWorksRef} />
        <RepScores />
        <CarouselContainer />
        <YouTubeIndex />
      </Layout>
    </div>
  );
};

Index.propTypes = {
  serverUserData: PropTypes.object,
};

export default Index;

export async function getServerSideProps() {
  const number = getRndInteger(0, 7);
  return {
    props: { randomNumber: number },
  };
}
