/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from "react";
import dynamic from "next/dynamic";

const HeroV1 = dynamic(() => import("./HeroABTesting/HeroV1"));
const HeroV2 = dynamic(() => import("./HeroABTesting/HeroV2"));
const HeroV3 = dynamic(() => import("./HeroABTesting/HeroV3"));
const HeroV8 = dynamic(() => import("./HeroABTesting/HeroV8"));
const HeroV9 = dynamic(() => import("./HeroABTesting/HeroV9"));
const HeroV10 = dynamic(() => import("./HeroABTesting/HeroV10"));
const HeroV11 = dynamic(() => import("./HeroABTesting/HeroV11"));
const HeroControl = dynamic(() => import("./HeroABTesting/HeroControl"));

import useUserLogStatus from "../customHooks/useUserLogStatus";

const Hero = ({ randomNumber }) => {
  // Custom hook to check if user is logged in. Returns a boolean.
  const isLoggedIn = useUserLogStatus();

  const renderContent = React.useCallback(() => {
    switch (randomNumber) {
      case 0:
        return <HeroControl />;
      case 1:
        return <HeroV1 />;
      case 2:
        return <HeroV2 />;
      case 3:
        return <HeroV3 />;
      case 4:
        return <HeroV8 />;
      case 5:
        return <HeroV9 />;
      case 6:
        return <HeroV10 />;
      case 7:
        return <HeroV11 />;
      default:
        return null;
    }
  }, [isLoggedIn]);
  return <div className="normalize--landing_hero">{renderContent()}</div>;
};

export default Hero;
